import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import AOS from '../../node_modules/aos/dist/aos';
import darshan from '../assets/images/paintings/darshanupdated.JPG';
function HomePage(){
    return(
        <div>     
            <div className='main' data-aos="fade-up">
                <img src={darshan} height = "500px"></img>
                <h1>Hi, I'm <span style={{color: 'green'}}>Darshan</span></h1>
                <p>I study Computer Science at the University of Illinois at Urbana-Champaign.</p>
                <p>This summer, I worked at <a href="https://www.crowdstrike.com/" style={{color: 'blue'}}>CrowdStrike</a>, where I worked on the sensor team optimizing the testing frameworks. I also migrated build time visualizations across platforms from Splunk to Falcon Logscale. During Summer 2023, I worked at <a href="https://www.sentinelfertigation.com/" style={{color: 'blue'}}>Sentinel Fertigation</a>, a startup in the agroTech space. I collaborated with some incredibly talented people, spent a summer in Lincoln, NE, and built a fullstack app that helps streamline the company's sales process. At school, I was a techlead at <a href="https://www.uiucdisruptionlab.org/" style={{color: 'blue'}}> Disruption Lab</a>, where I worked on a project developing algorithmic trading strategies for $100k worth of cryptocurrency. I also consulted for an autonomous tractor company through <a href="http://www.otcr.illinois.edu/" style={{color: 'blue'}}> OTCR</a>, another organization at UIUC. Outside of school, I'm contracting at <a href="https://stackendsolutions.com/" style={{color: 'blue'}}> Stackend Solutions</a>, working on automation and integration projects, and some AWS/database migration work for a dating app. During summer 2022, I did development work at <a href="https://www.linkedin.com/company/singh-capital/" style={{color: 'blue'}}>Singh Capital</a>, where I did fullstack work on a build-your-own ETF mobile app.</p>
                <p>Here's some videos of me playing piano, my Summer 2020 hobby</p>
                <p><a href="https://photos.app.goo.gl/wUYoAZovByLgJbgFA" style={{color: 'blue'}}>Claire de Lune (Debussy) </a><a href="https://photos.app.goo.gl/Vu7vCEpaUFADcRen7" style={{color: 'blue'}}>Nocturne in E Flat Major (Chopin) </a><a href="https://photos.app.goo.gl/vwurSgVfXk717HpA6" style={{color: 'blue'}}>Liebestraum (Lizt)</a></p>
                <ButtonToolbar fluid={true} ml-2>
                    <Button variant = "outline-success" href='https://rose-debra-61.tiiny.site' target='_blank'>Resume</Button>
                    <Button variant = "outline-success" href='https://www.linkedin.com/in/darshangupta/' target='_blank'>Linkedin</Button>
                    <Button variant = "outline-success" href='https://github.com/darshangupta' target='_blank'>Github</Button>
                </ButtonToolbar>
            </div>

        </div>
    );
}
export default HomePage;
AOS.init();