  
import React from 'react';
import Container from 'react-bootstrap/Container';

function Footer() {

    return(
        <footer className="mt-5 border-top">
            <Container fluid={true}>
                <small className="p-2 d-flex justify-content-center text-center">
                    Darshan Gupta
                </small>
            </Container>
        </footer>
    );

}

export default Footer;