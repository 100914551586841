import React from 'react';
import AOS from '../../node_modules/aos/dist/aos';

function AboutPage(){
    return(
        <div className="main" data-aos="fade-up">
            <h1>Experience</h1>
            <p>If you'd like to add to this list (by hiring me), please refer to the contact page and send me an email :) Everything here is in more detail on my <a href='https://www.nuc.us/VynBnvSr' style={{color: 'blue'}}>Resume</a></p>
            <h2>Work</h2>
            <h3>CrowdStrike</h3>
            <p>Optimizing the sensor test framework</p>
            <h3>Sentinel Fertigation: June 2023 - December 2023</h3>
            <p>Made a demo of N-Time (main product) subsequently was debuted in trade shows internationally</p>
            <h3>Stackend Solutions: December 2022 - Present</h3>
            <p>Writing automation scripts, doing a lot of web scraping</p>
            <h3>Disruption Lab: August 2022 - Present</h3>
            <p>Productionizing an algorithmic cryptocurrency trading strategy</p>
            <h3>Singh Capital Partners: June 2022 - August 2022</h3>
            <p>Developed API Transfer Protocols, made some mockups of a frontend in Figma and later developed them in React</p>
            <h3>Digital Lantern: June 2021 - August 2021</h3>
            <p>Wrote a Python script to classify network traffic as either an IP address, mac address, domain, and web address... I spent most of my time doing analyst work during this internship (I was hired as one), but I squeezed some development experience out of it as well</p>
            <h2>Projects</h2>
            <h3>This Website: December 2022</h3>
            <p>Developed in React, meant for recruiters, hiring managers, friends, really whoever to find. If you'd like me to make one for you (or code just about anything for you), send me an email, I'd love to talk.</p>
            <h3>uiuc.link: August 2022</h3>
            <p>Dating app for UIUC students. Although none of my friends found love from this, plenty of other people did (503 users and 66 matches within first two weeks of deployment)</p>
        </div>
        
    );
}
export default AboutPage;
AOS.init();